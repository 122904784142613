import { useContext } from "react";
import { PageTracker } from "@mytutor/snowplow-tracking";
import { AnalyticsConsentContext } from "../../context/consent";

type AppPageTrackerProps = {
  title: string;
};
export const SnowplowPageTracker = (props: AppPageTrackerProps) => {
  const { title } = props;

  if (
    !useContext(AnalyticsConsentContext) &&
    process.env.NEXT_PUBLIC_FORCE_ENABLE_SP_ANALYTICS !== "true"
  ) {
    return null;
  }

  return (
    <PageTracker
      isEnabledEnv={process.env.NEXT_PUBLIC_INIT_SNOWPLOW ?? ""}
      title={title}
    />
  );
};
